.layout-minimal {
    height: 100%;
    background: color("background");

    &-header {
        background-color: color("white");
        padding: 50px 40px 30px;
    }

    &-title {
        padding: 40px 120px;
    }

    &-main {
        width: 500px;
        padding: 20px;
        margin: 0 auto;
    }

    &-logo {
        max-width: 350px;

        & img {
            max-width: 100%;
            height: auto;
        }
    }

    &-applications {
        display: flex;
        justify-content: center;
        padding: 40px 20px;

        & img {
            width: 60px;
            height: auto;
            padding: 5px;
        }
    }
}
