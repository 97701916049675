.ordersDocuments {
    .document {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px;
        text-align: center;
        width: 120px;

        img {
            margin: auto;
            width: 50px;
            display: block;
            fill: map-get($colors, "black");
        }

        a {
            color: map-get($colors, "black");
        }
    }
}

.ordersEvents {
    .list {
        .statut {
            border-radius: 10px;
            padding: 0 5px;
            text-transform: uppercase;
            color: map-get($colors, "white");
            background-color: #f18fa6;

            &-Allocation,
            &-Rupture {
                background-color: #ff8c60;
            }
            &-Expedition {
                background-color: #9ccd4f;
            }
            &-Facturation {
                background-color: #0dca43;
            }
            &-Lancement {
                background-color: #367fff;
            }
            &-Livraison {
                background-color: #06cfbe;
            }
            &-Creation {
                background-color: #4a81bc;
            }
            &-Modification {
                background-color: #837dff;
            }
        }
    }
}

.ordersCommercial {
    label {
        color: map-get($colors, "blue_dark");
    }

    fieldset {
        border-left-style: none;
        border-right-style: none;
        border-bottom-style: none;
        border-top: 2px solid map-get($colors, "blue");
        padding: 0;
        overflow: hidden;
        position: relative;

        .right-legend {
            position: absolute;
            top: -25px;
            right: 20px;
            font-weight: bold;
        }

        legend {
            margin: 15px 0;
            color: map-get($colors, "blue");
            padding-right: 10px;
            font-weight: bold;
        }
    }
}
