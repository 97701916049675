.stepper {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    overflow: auto;

    &_item {
        $self: &;
        display: flex;
        border-top: 2px solid color("black");
        padding: 10px 0;
        cursor: pointer;
        color: color("black");
        text-align: center;
        align-items: center;

        &_icon {
            display: block;
            padding: 0;
            font-size: 16px;
        }

        &_title {
            font-size: 14px;
            margin-bottom: 2px;
            padding: 0 10px;
        }

        &-current {
            color: text-color("blue") !important;
            background: #f1f1f1;
            border-color: color("blue");
            font-weight: bold;
        }

        &-disabled {
            color: color("disabled") !important;
            border-color: color("disabled");
            cursor: initial;

            &:hover {
                color: color("disabled") !important;
                border-color: color("disabled");
            }
        }
    }
}
