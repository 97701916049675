.container {
    &__form {
        max-width: 800px;
        &-actions {
            display: flex;
            justify-content: flex-end;
            & > *:not(:first-child) {
                margin-left: 20px;
            }
        }
        &--little {
            margin: auto;
            width: 640px;
            max-width: 100%;
        }
    }

    &__title {
        margin-top: 60px;
        margin-bottom: 20px;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }
}
