.flash-message {
    display: flex;
    align-items: flex-start;
    width: 100%;
    min-width: 50px;
    height: auto;
    padding: 15px 20px;
    font-size: 16px;

    &__container {
        display: block;
        position: fixed;
        z-index: 1500;
        top: $topbar-height;
        right: 0;
        width: 100%;

        & > *:not(:first-child) {
            margin-top: 10px;
        }

        @include media-breakpoint-up("large") {
            top: $topbar-height;
            right: 5%;
            width: 40%;
            padding-top: 10px;
        }
    }

    &__title {
        font-weight: bold;
        // color: color("gris_eres");
        margin-top: 5px;
    }

    &__content {
        // color: color("gris_eres");
        flex-grow: 1;
        margin-top: 5px;
    }

    &__icon + &__content,
    &__icon + &__title {
        margin-left: 20px;
    }

    &__title + &__content {
        margin-left: 5px;
    }

    &__remove-btn {
        background-color: color("transparent");
        border: none;
    }

    &--error {
        color: color("error");
        background-color: color("error-bg");
        border: 1px solid color("error-border");
    }

    &--info {
        color: color("info");
        background-color: color("info-bg");
        border: 1px solid color("info-border");
    }

    &--success {
        color: color("success");
        background-color: color("success-bg");
        border: 1px solid color("success-border");
    }

    &--warning {
        color: color("warning");
        background-color: color("warning-bg");
        border: 1px solid color("warning-border");
    }

    &--show {
        opacity: 1;
    }

    &--hide {
        opacity: 0;
    }
}
