.OrderRequest,
.OrderRequestView {
    .list {
        .order-statut {
            border-radius: 10px;
            padding: 0 5px;
            text-transform: uppercase;
            color: map-get($colors, "white");
            background-color: #f18fa6;

            &-INTEGRATED {
                background-color: #06cfbe;
            }
            &-TRANSMITTED {
                background-color: #4a81bc;
            }
            &-DRAFT {
                background-color: #ff8c60;
            }
        }

        .stock {
            &-ico {
                display: inline-block;
                border-radius: 10px;
                width: 10px;
                height: 10px;

                &-good {
                    background-color: #0dca43;
                }
                &-warning {
                    background-color: #ff8c60;
                }
                &-danger {
                    background-color: #f18fa6;
                }
            }

            &-good {
                color: #0dca43;
                width: 50px;
            }
            &-warning {
                color: #ff8c60;
                width: 50px;
            }
            &-danger {
                color: #f18fa6;
                width: 50px;
            }
        }
    }
}
