.btn {
    $self: &;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    min-width: 120px;

    border: none;
    border-radius: 16px;

    text-align: center;
    vertical-align: middle;

    font-size: 11px;
    text-transform: uppercase;

    padding: 0px 20px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }

    &-icon {
        &--right {
            position: absolute;
            right: 0;
        }
        &--left {
            left: 0;
        }
    }

    &--small {
        min-height: 30px;
        max-height: 30px;
        height: 30px;
        margin: 10px;
        min-width: 80px;
        border-radius: 10px;

        text-transform: initial;
    }

    &--small-margin {
        height: 1vw;
        margin: 1vw;
        font-size: 1em;
        border-radius: 10px;

        text-transform: initial;
    }

    &--large {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    &--primary {
        position: relative;
        background-color: color("blue");
        color: text-color("white");

        &:hover {
            background-color: color("blue_hover");
        }

        &:focus {
            border: none;
        }
    }

    &--danger {
        position: relative;
        background-color: color("danger");
        color: text-color("white");

        &:hover {
            background-color: color("danger_hover");
        }

        &:focus {
            border: none;
        }
    }

    &--secondary {
        position: relative;
        background-color: color("green");
        color: text-color("white");

        &:hover {
            background-color: color("green_hover");
        }

        &:focus {
            border: none;
        }
    }

    &--disabled,
    &:disabled {
        position: relative;
        background-color: color("grey");
        color: text-color("white");

        &:hover {
            background-color: color("grey");
            cursor: not-allowed;
        }

        &:focus {
            border: none;
        }
    }

    &--icon {
        padding: 0;
        min-width: 25px;
        min-height: 25px;
        font-weight: bold;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    & > img {
        max-height: 100%;
        max-width: 100%;
    }

    &-close {
        cursor: pointer;
        position: relative;
        width: 28px;
        height: 28px;
        border-radius: 28px;
        background: transparent;
        border: 2px solid color("grey-base");

        &::after,
        &::before {
            content: "";
            display: block;
            height: 80%;
            width: 2px;
            background-color: color("grey-base");
            position: absolute;
            top: 10%;
            left: calc(50% - 1px);
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }

        @include event_attention() {
            border: 2px solid darken(color("grey-base"), 10);
            text-decoration: none;
            outline-width: 0;
            &::after,
            &::before {
                background-color: darken(color("grey-base"), 10);
            }
        }
    }
}
