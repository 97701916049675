.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    font-family: $font-default;
    font-weight: 500;
    margin: 0;
}

.hr {
    width: 100%;
    height: 1px;
}
