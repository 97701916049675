.invoice {
    &-analyze {
        &-axis {
            &__container {
                display: flex;
                justify-content: center;
            }

            &__item {
                text-align: left;
                width: 20%;
                padding-left: 5px;
                padding-right: 5px;

                & label {
                    font-size: 11px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }

            &__add {
                display: inline-block;
                &:hover {
                    cursor: pointer;
                    span {
                        color: rgba(12, 52, 102, 0.75);
                    }

                    .btn {
                        background-color: color("blue_hover");
                    }
                }

                & .btn {
                    min-width: auto;
                }

                & span {
                    color: #0c3469;
                    text-decoration: underline;
                }
            }

            @include media-breakpoint-down("medium") {
                &__container {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                &__item {
                    width: 100%;
                }
            }

            @include media-breakpoint-down("xsmall") {
                &__container {
                    display: grid;
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }
        }
    }
}
