.sidebar {
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;

    min-width: 300px;
    height: 100%;

    overflow-y: auto;

    background: #292b2c;
    color: #fff;

    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
    -webkit-transition: -webkit-transform 0.15s;
    transition: transform 0.15s;

    @include media-breakpoint-up("large") {
        -webkit-transform: none !important;
        transform: none !important;
        box-shadow: none !important;
    }

    &.open {
        -webkit-transform: none !important;
        transform: none !important;
        box-shadow: none !important;
    }

    &-header {
        background: #003b7a;

        &-icon {
            display: block;
            float: left;
            width: 2.5rem;
            height: 2.5rem;
        }

        &-title {
            margin-left: 2.5rem;
            margin-bottom: 0;
            padding: 0 0.5rem;
            font-size: 1em;
            line-height: 2.5rem;
        }
    }

    &-account {
        position: relative;
        height: 180px;
        background-image: url($path-img + "cover.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #464a4c;
        padding: 1rem;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(to bottom, rgba(30, 32, 33, 0) 0%, rgba(30, 32, 33, 0.65) 100%);
        }

        &-wrap {
            position: absolute;
            z-index: 10;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0.5rem 1rem;
        }

        &-logo {
            display: block;
            width: 80px;
            height: 80px;
            margin-bottom: 1rem;
            border-radius: 50%;
            box-shadow: 2px 2px 5px rgba(41, 43, 44, 0.2);
        }

        &-name {
            padding: 0;
            border: 0;
            background: transparent;
            color: #fff;
            font-size: 1em;
            outline: none !important;
            text-shadow: 1px 1px 3px rgba(30, 32, 33, 0.8);
        }
    }

    &-nav {
        h2 {
            margin: 0;
            padding: 1.5rem 1rem 0.5rem;
            font-size: 1em;
            color: #636c72;
        }

        ul {
            margin: 0;
            padding-left: 0;
            list-style: none;
        }

        a {
            display: block;
            padding: 0 1rem;
            line-height: 2rem;
            text-decoration: none;
            color: #cbd3d6;
            -webkit-transition: background 0.15s;
            transition: background 0.15s;

            &:hover {
                background: #1e2021;
                text-decoration: none;
                color: #007bff;
            }
        }
    }

    &-overlay {
        z-index: 999;
        opacity: 1;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        background: rgba(247, 247, 249, 0.8);
        -webkit-transition: all 0.15s;
        transition: all 0.15s;
    }
}
