$font_weights: 300, 400, 600, 700, normal;
$font_sizes: 12, 14, 16, 18, 20, 70;

.bold {
    font-weight: bold !important;
}

.italic {
    font-style: italic !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.no-wrap {
    white-space: nowrap;
}

.pre-line {
    white-space: pre-line;
}

.font {
    &__weight {
        @each $value in $font_weights {
            &--#{$value} {
                font-weight: $value;
            }
        }
    }

    &__ls-wide {
        letter-spacing: 2.4px;
    }
    &__lh {
        @for $i from 0 through 8 {
            &--#{$i} {
                line-height: #{$spacing-unit * $i} !important;
            }
        }
    }

    &__size {
        @each $value in $font_sizes {
            &--#{$value} {
                font-size: #{$value + "px"};
            }
        }
    }

    &--heading {
        font-family: Arial, Helvetica, sans-serif !important;
    }

    &--text {
        font-family: Arial, Helvetica, sans-serif !important;
    }
}
