.line-top-margin > td {
    padding-top: 16px !important;
}

.table-ca-result {
    .input-container {
        min-height: 30px;

        input {
            min-height: 30px;
        }
    }
}
