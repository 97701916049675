.bg {
    @each $colorName, $_ in $colors {
        &--#{$colorName} {
            background-color: color($colorName) !important;
        }
    }
}

.bg-rotate {
    @each $colorName, $_ in $colors {
        &--#{$colorName} {
            overflow: visible;

            &:after {
                display: block;
                content: "";
                position: absolute;
                transform: rotate(-10deg);
                background-color: color($colorName);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
}

.text-color {
    @each $colorName, $_ in $colors {
        &--#{$colorName} {
            color: color($colorName) !important;
        }
    }
}
