.layout {
    display: flex;

    width: 100%;
    height: 100vh;

    &-main {
        position: absolute;
        display: flex;

        height: 100%;
        width: 100%;
        background: color("background");

        @include media-breakpoint-up("large") {
            left: 300px;
            width: calc(100% - 300px);
        }

        & .container {
            &-main {
                overflow-y: auto;
                padding: 1rem 1rem;
                position: absolute;
                top: 2.5rem;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        & .application-title {
            display: flex;
            align-items: flex-start;
            margin-bottom: 24px;
            justify-content: left;

            & img {
                height: 40px;
            }
        }
    }
}
