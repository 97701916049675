.loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    &--small {
        width: 36px;
        height: 36px;
    }
    &--xsmall {
        width: 17px;
        height: 17px;
    }
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid transparent;
    border-top-color: color("blue");
}

.loader:before {
    z-index: 100;
    animation: spin 1s infinite;
}

.loader:after {
    border: 5px solid #ccc;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
