.popover {
    position: relative;
    &-content {
        min-width: 50vw;
        max-width: 80vw;

        position: absolute;
        right: -100%;
        bottom: 100%;
        margin-bottom: 50%;

        background-color: color("background");
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);

        opacity: 0;
        visibility: hidden;

        &.show {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.15s linear;
        }

        &.arrow::before,
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            right: 24px;
        }

        &.arrow::before {
            top: 101%;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid rgba(0, 0, 0, 0.15);
        }

        &.arrow::after {
            top: 100%;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid color("background");
        }

        & .popover-inner {
            padding: 0.5rem 0.75rem;
            text-align: left;
            white-space: pre-wrap;
        }
    }
}
