.hidden {
    display: none !important;
    @each $breakpoint, $_ in $breakpoints {
        @include media-breakpoint-down($breakpoint) {
            &--down-#{$breakpoint} {
                display: none !important;
            }
        }
        @include media-breakpoint-up($breakpoint) {
            &--up-#{$breakpoint} {
                display: none !important;
            }
        }
    }
}
