.topbar {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 2.5rem;

    background: #007bff;
    box-shadow: 0 0 30px rgba(41, 43, 44, 0.15);
    color: #fff;

    display: flex;
    align-items: center;

    &-toggler {
        @include media-breakpoint-up("large") {
            display: none;
        }

        padding-left: 12px;
        padding-right: 12px;

        i {
            font-size: 30px !important;
        }
    }

    &-toggler:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}
