.calendrier {
    &-topbar {
        display: flex;

        &--navigation {
            display: flex;
            flex-grow: 1;
            align-items: center;

            &-date {
                width: 180px;
                color: color("primary-base");
                font-size: 14px;
                text-transform: uppercase;
                text-align: center;
                margin: 0 5px 0 5px;
            }
        }

        &--end-date {
            margin-left: 25px;
        }

        &--actions {
            margin-left: auto;
        }
    }
}
