.tabs {
    list-style: none;
    display: inline-flex;
    font-size: 12px;
    margin: 0;
    padding: 0;

    & > li {
        padding: 2px 10px;
        text-transform: uppercase;
        font-weight: bold;
        color: #dee2e5;
        border-bottom: 2px solid #ebebeb;

        &.active {
            color: color("blue_dark");
            border-bottom: 3px solid #5ecb45;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.paperTabs {
    position: relative;
    top: 1px;

    list-style: none;
    display: inline-flex;
    font-size: 14px;
    margin: 0;
    padding: 0;

    & > li {
        padding: 15px 25px;
        text-transform: uppercase;
        font-weight: bold;
        color: #aeaeae;
        border: 1px solid #ebebeb;
        background-color: #fafafa;
        border-left: none;

        &.active {
            color: color("black");
            background-color: color("white");
            border-bottom: none;
        }
        &:hover {
            cursor: pointer;
        }

        &:first-child {
            border-left: 1px solid #ebebeb;
            border-radius: 10px 0 0 0;
        }

        &:last-child {
            border-radius: 0 10px 0 0;
        }
    }
}
