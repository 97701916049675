.dashboard {
    &-header {
        margin-bottom: 50px;

        &__subtitle {
            margin: 10px 0 0;
            color: color("grey");
        }
    }

    &-container {
        .paper {
            padding: 15px 20px;
            position: relative;
        }
    }

    &-card {
        $card: &;

        &__title {
            display: flex;
            font-weight: bold;
            align-content: center;
        }

        &__icon {
            height: 20px;
            margin-right: 10px;
        }

        &__border {
            height: 70px;
            position: absolute;
            top: 17px;
            left: -2px;
            max-height: calc(100% - 30px);

            &--green {
                border-left: solid 4px color("green");
            }

            &--green_2 {
                border-left: solid 4px color("green_2");
            }

            &--cyan {
                border-left: solid 4px color("cyan");
            }
        }

        &__container {
            $container: &;

            display: flex;
            flex-direction: column;

            .important {
                color: color("blue");
                font-size: 45px;
                font-weight: bold;
                margin: 0;
            }

            label {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
            }

            input {
                &::placeholder {
                    color: color("grey");
                    font-style: italic;
                }
            }

            &--clearfix {
                padding-bottom: 90px;

                #{$card}__button {
                    position: absolute;
                    bottom: 15px;
                    left: 20px;
                    width: calc(100% - 40px);
                }
            }
        }
    }

    &-news {
        align-items: center;
        display: flex;
        justify-content: stretch;
        margin-top: 20px;

        &:hover {
            cursor: pointer;
        }

        &__icon {
            margin-right: 30px;
            max-width: 120px;
            max-height: 120px;
        }

        &__container {
            align-self: stretch;
            display: flex;
            flex-direction: column;
        }
    }
}
