.view {
    font-size: 12px;

    .label {
        text-transform: uppercase;
        color: color("blue_dark");
        font-size: 11px;
        text-align: left;
        font-weight: bold;
        margin-right: 16px;
    }
}
