[class*=" grid--"],
[class^="grid--"] {
    display: grid;
    grid-auto-flow: dense;
}

.autogrid,
.grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    &--items-center {
        place-items: center;
    }

    &--self-center {
        place-self: center;
    }
}

@for $i from 1 through $grid-number-cols {
    .grid--#{$i} {
        grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
}

@for $i from 1 through $grid-number-cols {
    .grid__col--#{$i} {
        grid-column: auto / span #{$i};
    }

    .grid__row--#{$i} {
        grid-row: auto / span #{$i};
    }
}

.grid--has-gutter {
    grid-gap: 10px;

    &-x {
        grid-row-gap: 10px;
    }

    &-2x {
        grid-row-gap: 20px;
    }

    &-3x {
        grid-row-gap: 30px;

        @include media-breakpoint-up("small") {
            grid-column-gap: 30px;
        }
    }

    &-5x {
        grid-row-gap: 50px;

        @include media-breakpoint-up("small") {
            grid-column-gap: 50px;
        }
    }

    @include media-breakpoint-up("small") {
        grid-column-gap: 20px;
    }
}

.grid__item--first {
    order: -1;
}

.grid__item--last {
    order: 1;
}

.grid__offset {
    visibility: hidden;
}

.grid__col--all {
    grid-column: 1 / -1;
}

.grid__row--all {
    grid-row: 1 / -1;
}

$breakpointsReverse: reverse($breakpoints);

@each $breakpoint, $_ in $breakpointsReverse {
    $next: breakpoint-next($breakpoint, $breakpoints);
    @if ($next) {
        .grid__item--#{$next}-first {
            order: -1;
        }
        .grid__item--#{$next}-last {
            order: 1;
        }
        .grid__offset--#{$next} {
            visibility: hidden;
        }

        @include media-breakpoint-down($breakpoint) {
            @for $i from 1 through $grid-number-cols {
                .grid--#{$next}-#{$i} {
                    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
                }
                .grid__col--#{$next}-#{$i} {
                    grid-column: auto / span #{$i};
                }
                .grid__row--#{$next}-#{$i} {
                    grid-row: auto / span #{$i};
                }
            }
        }
    }
}
