$dropzone-height: 140px;
$dropzone-item-height: 150px;
$dropzone-item-width: 160px;

.dropzone {
    border: 1px solid #acacac;
    border-radius: 10px;
    min-height: $dropzone-height;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &-content {
        position: relative;
        text-align: center;
        border-radius: 2px;
        background: #eeeeee;
        height: $dropzone-item-height;
        width: $dropzone-item-width;
        padding: 5px;
        margin: 10px 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: default;
        border-radius: 10px;

        &-filename {
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 24px;
        }

        &-error {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            & > i {
                color: red;
                font-size: 60px;
                opacity: 0.75;
            }
        }
    }

    &-placeholder {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: #acacac;

        & > .material-icons {
            font-size: 42px;
            margin-bottom: 10px;
        }
    }
}
