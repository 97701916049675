.modal-open {
    // overflow-y: hidden;
}

.modal {
    position: relative;
    display: block;
    width: 70%;
    max-width: 90%;
    max-height: 98vh;
    min-height: $spacing-unit * 6;
    padding: $spacing-unit * 3;
    background-color: color("white");
    border-radius: 10px;
    overflow-x: auto;
    z-index: 900;
    transform: scaleX(0) scaleY(0);
    opacity: 0;
    will-change: opacity, transform;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &--no-overflow {
        overflow-x: inherit;
    }

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    &--show {
        transform: scaleX(1) scaleY(1);
        opacity: 1;
    }
    &--auto {
        width: auto;
    }
    &--small {
        width: 540px;
    }
    &--large {
        width: 860px;
    }
    &--x-large {
        width: 1000px;
    }
    &--auto,
    &--small,
    &--large,
    &--x-large {
        max-width: 90vw;
    }
    &-close {
        position: absolute;
        top: $spacing-unit * 2;
        right: $spacing-unit * 2;
        border: 0;
        background: none;
        color: color("blue");
        cursor: pointer;

        &:focus {
            outline: 0;
        }
        .svg-inline--fa {
            font-size: 1.9rem;
        }
    }
    &-body {
        //margin-top: 20px;

        .crud-toolbar {
            display: none;
        }
    }
    &-title,
    .page-title {
        margin: 0 0 $spacing-unit * 2;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;
        text-align: center;
        color: color("blue");
    }
    &-wrapper {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &-overlay {
        display: block;
        position: fixed;
        z-index: 1002;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: color("black");
        opacity: 0.3;
        will-change: opacity;
        transition: opacity 0.3s ease-in-out;

        &--dismiss {
            opacity: 0;
        }
    }
}
