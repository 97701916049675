.table-overflow-wrapper {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.table {
    $baseClass: &;
    width: 100%;
    max-width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
    &__body {
        & #{$baseClass}__row {
            border-bottom: 1px solid color("grey-300");
            &:first-child {
                border-top: 1px solid color("grey-300");
            }
            &--clickable {
                cursor: pointer;
                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.07);
                }
            }
        }
        & #{$baseClass}__col {
            font-weight: normal;
            color: color("grey-dark");
            padding: $spacing-unit * 2;
        }
    }
    &__head {
        & #{$baseClass}__col {
            font-weight: bold;
            color: color("shades-black");
            padding: $spacing-unit * 2;
        }
    }
    &__foot {
        & #{$baseClass}__col {
            font-weight: normal;
            color: color("grey-dark");
            padding: $spacing-unit * 2;
        }
    }

    & #{$baseClass}__col--checkbox {
        padding: 0;
        width: 1px;
    }

    &__row {
        &--total {
            background-color: color("grey");
            & #{$baseClass}__col {
                font-weight: bold;
                color: text-color("black");
                padding: $spacing-unit * 2 $spacing-unit * 2;
            }
        }
        &--small {
            & #{$baseClass}__col {
                background-color: color("grey-light");
                color: color("shades-black");
                font-size: 10px;
                padding: $spacing-unit $spacing-unit * 2;
            }
        }
        &--group {
            .table__col {
                background-color: color("grey-light");
                color: color("shades-black");
                font-weight: bold;
            }
        }
    }

    &-sort-label {
        display: block;
        width: 100%;
        color: inherit;
        background-color: transparent;
        text-align: left;
        user-select: none;
        border: none;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        line-height: 1;
        cursor: pointer;
        transition: 0.3s padding ease-out;

        @include event_attention() {
            background-color: rgba($color: #000000, $alpha: 0.07);
            text-decoration: none;
            outline-width: 0;
        }
    }

    &-checkbox {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: 0 $spacing-unit;

        &__input {
            top: 0;
            left: 0;
            width: 100%;
            cursor: inherit;
            height: 100%;
            margin: 0;
            opacity: 0;
            padding: 0;
            z-index: 1;
            position: absolute;

            @include event_attention() {
                ~ #{$baseClass}-checkbox__label:before {
                    border-color: color("grey");
                }
            }

            &--focus {
                ~ #{$baseClass}-checkbox__label:before {
                    border-color: color("grey");
                }
            }

            &:checked {
                ~ #{$baseClass}-checkbox__label {
                    &:before {
                        border-color: color("grey");
                    }

                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }

        &__label {
            position: relative;
            min-height: 1.5em;
            padding-left: 1.5em;
            display: inline-flex;
            align-items: center;

            &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 0;
                display: block;
                width: 12px;
                height: 12px;
                background-color: transparent;
                border: color("grey-base") solid 1px;
                border-radius: $border-radius / 2;
            }
        }
        .svg-inline--fa {
            position: absolute;
            top: 7px;
            left: 13px;
            font-size: 0.6rem;
            color: color("grey");
        }
    }
    &__col {
        &.has-logo {
            width: 125px;
            height: 48px;
        }
    }
}
