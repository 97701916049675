.list {
    width: 100%;
    border-spacing: 0 0;

    &-container {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    & > thead > tr > th {
        padding: 25px 5px;

        text-transform: uppercase;
        color: color("blue_dark");
        font-size: 12px;
        text-align: center;

        border-bottom: 1px solid #c5c5c5;

        & .DateRangePicker {
            .DateInput {
                min-width: unset;
                width: 130px;
            }

            .DateInput_input {
                font-size: 14px;
                text-align: center;
            }

            .DateRangePicker_picker {
                text-transform: lowercase;
            }

            @include media-breakpoint-down("medium") {
                .DateRangePickerInput {
                    display: flex;
                    flex-direction: column;
                }

                & .DateInput {
                    width: 100%;
                }
            }
        }
    }

    & > tbody > tr > td {
        padding: var(--padding-td, 20px 5px);
        font-size: 16px;

        border-bottom: var(--border-bottom-td, 1px solid #c5c5c5);
    }

    &-actions {
        text-align: right !important;

        &-item {
            margin: 0 1px;
        }
        &-item-download {
            margin: 0 1px;
            cursor: pointer;
        }
    }

    &_toolbar {
        padding-bottom: 15px;

        &-form-actions {
            margin: 5px 0 0 auto;
            white-space: nowrap;
        }
    }
}
