.cartridge {
    $self: &;

    align-items: center;
    background-color: color("background");
    border-radius: 10px;
    border: 1px #ebebeb solid;
    display: flex;
    justify-content: stretch;
    margin: 0 auto;
    padding: 15px 20px;

    &__icon {
        font-size: 30px;
        margin-right: 20px;
    }

    &__date {
        font-size: 12px;
    }

    &__filename {
        font-size: 14px;
        font-weight: bold;
    }

    &__date,
    &__filename {
        margin: 0;
    }

    & + & {
        margin-top: 10px;
    }

    &__actions {
        margin-left: auto;

        & #{$self}__icon {
            border-radius: 10px;
            font-size: 15px;
            margin: 0;
            padding: 8px;
        }
    }
}
