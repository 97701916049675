.paper {
    background-color: color("white");
    padding: 20px 30px;

    border-radius: 10px;
    border: 1px #ebebeb solid;

    &__title {
        margin-bottom: 20px;
    }

    &__tabs {
        @extend .paper;
        border-radius: 0 10px 10px 10px;
    }
}

.paper-flat {
    background-color: color("white");
    border: 1px #ebebeb solid;

    &__title {
        padding: 20px 30px;
        color: #ffffff;
    }

    &__body {
        background: #f1f1f1;
        padding: 20px 30px;
    }
}
