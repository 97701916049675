.pagination {
    @extend .flex;

    margin-top: 20px;

    &-current {
        border: 1px solid color("blue");
    }

    &-nav {
        @extend .flex;
        list-style-type: none;
        margin: auto;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;

        li {
            padding: 5px 5px;
            margin: 2px 0px;
            text-align: center;
            color: #707070;

            &:hover {
                cursor: pointer;
            }

            span {
                color: color("blue");
            }

            a {
                color: color("blue");
            }
        }
    }

    &-counter {
        span {
            color: color("black");

            &:last-child {
                padding-right: 5px;
            }
        }

        span.pagination-counter-current {
            padding: 0 5px;
            color: color("white");
            background-color: color("grey");
        }
    }

    &-total {
        font-weight: 600;
        margin-left: 10px;

        span {
            font-weight: 400;
        }
    }

    &-per_page {
        input {
            width: 60px !important;
        }
    }
}
