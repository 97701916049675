.margin {
    &--auto {
        margin: auto;
    }
    &-top--auto {
        margin-top: auto;
    }
    &-bottom--auto {
        margin-bottom: auto;
    }
    &-right--auto {
        margin-right: auto;
    }
    &-left--auto {
        margin-left: auto;
    }
}

.padding {
    &--auto {
        padding: auto;
    }
    &-top--auto {
        padding-top: auto;
    }
    &-bottom--auto {
        padding-bottom: auto;
    }
    &-right--auto {
        padding-right: auto;
    }
    &-left--auto {
        padding-left: auto;
    }
}

@for $i from 0 through 12 {
    .margin {
        &--#{$i} {
            margin: $spacing-unit * $i !important;
        }
        &-top--#{$i} {
            margin-top: $spacing-unit * $i !important;
        }
        &-bottom--#{$i} {
            margin-bottom: $spacing-unit * $i !important;
        }
        &-right--#{$i} {
            margin-right: $spacing-unit * $i !important;
        }
        &-left--#{$i} {
            margin-left: $spacing-unit * $i !important;
        }
    }
    .margin-negative {
        &-top--#{$i} {
            margin-top: -($spacing-unit * $i) !important;
        }
        &-bottom--#{$i} {
            margin-bottom: -($spacing-unit * $i) !important;
        }
        &-right--#{$i} {
            margin-right: -($spacing-unit * $i) !important;
        }
        &-left--#{$i} {
            margin-left: -($spacing-unit * $i) !important;
        }
    }
    .padding {
        &--#{$i} {
            padding: $spacing-unit * $i !important;
        }
        &-top--#{$i} {
            padding-top: $spacing-unit * $i !important;
        }
        &-bottom--#{$i} {
            padding-bottom: $spacing-unit * $i !important;
        }
        &-right--#{$i} {
            padding-right: $spacing-unit * $i !important;
        }
        &-left--#{$i} {
            padding-left: $spacing-unit * $i !important;
        }
    }
}
