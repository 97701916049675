.rightbar {
    min-width: 250px;
    max-width: 250px;
    padding: 15px;
    background: color("white");
    font-size: 12px;

    &-title {
        display: flex;
        align-items: center;

        padding: 15px 0px;
        margin-bottom: 15px;

        font-weight: bold;
        color: #0c3469;
        text-transform: uppercase;

        border-bottom: 1px solid #ebebeb;
    }

    &-customer-item {
        margin: 10px 0px;

        &-title {
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &-items-item {
        margin: 10px 0px;
        display: flex;

        &-title {
            overflow: hidden;
            width: auto;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-quantity {
            white-space: nowrap;
        }
    }

    &-footer {
        padding: 15px 0px;
        margin-top: 15px;

        border-top: 1px solid #ebebeb;
    }

    &-items-amount {
        font-size: 1.25em;
        text-transform: uppercase;

        border-top: 1px solid #ebebeb;

        .value {
            color: grey;
            text-align: right;
        }
        .amount {
            text-transform: capitalize;
            font-weight: bold;
            font-size: 1.5em;

            &-value {
                color: black;
                text-align: right;
            }
        }
    }
}
