$colors: (
    "white": #ffffff,
    "black": #2f2a2b,
    "grey": #6e6e6e,
    "transparent": rgba(255, 255, 255, 0),
    "background": #f8f8f9,
    "green": #10de89,
    "green_hover": rgba(16, 222, 137, 0.75),
    "green_2": #9ccd4f,
    "blue": #4a81bc,
    "blue_hover": rgba(74, 129, 188, 0.75),
    "blue_dark": #0c3469,
    "cyan": #07cebc,
    "orange": #fe8c60,
    "purple": #837dff,
    "disabled": #acacac,
    "danger": #f32013,
    "danger_hover": rgba(243, 32, 19, 0.75),
    "error": #c21324,
    "error-bg": #f8d7da,
    "error-border": #f5c6cb,
    "error-disabled": #f18fa6,
    "info": #004085,
    "info-bg": #cce5ff,
    "info-border": #b8daff,
    "success": #155724,
    "success-bg": #d4edda,
    "success-border": #c3e6cb,
    "warning": #856404,
    "warning-bg": #fff3cd,
    "warning-border": #ffeeba,
    "calendar-maroon": #800000,
    "calendar-brown": #9a6324,
    "calendar-olive": #808000,
    "calendar-teal": #469990,
    "calendar-navy": #000075,
    "calendar-red": #e6194b,
    "calendar-orange": #f58231,
    "calendar-yellow": #ffe119,
    "calendar-lime": #bfef45,
    "calendar-green": #3cb44b,
    "calendar-cyan": #42d4f4,
    "calendar-blue": #4363d8,
    "calendar-purple": #911eb4,
    "calendar-magenta": #f032e6,
    "calendar-pink": #fabed4,
    "calendar-apricot": #ffd8b1,
    "calendar-mint": #aaffc3,
    "calendar-goldenrod": #daa520,
    "calendar-lightslategray": #778899,
    "calendar-darkkhaki": #bdb76b,
    "calendar-lavender": #e6e6fa,
    "calendar-mustard": #bda038,
    "shades-black": #000000,
    "shades-white": #ffffff,
    "shades-grey": #f5f6fa,
    "shades-transparent": transparent,
    "grey-base": #c0c3cd,
    "grey-light": #f7f7fb,
    "grey-dark": #9191a4,
    "grey-disabled": #abb3b9,
    "grey-disabled-text": #545454,
    "grey-50": #f7f6fb,
    "grey-100": #ecedf0,
    "grey-200": #e0e1e6,
    "grey-300": #d4d9e7,
    "grey-400": #c9ccd5,
    "grey-500": #c0c3cd,
    "grey-600": #babdc8,
    "grey-700": #b2b5c1,
    "grey-800": #aaaeba,
    "grey-900": #9ca1ae,
    "primary-base": #2babe2,
    "primary-dark": #007cb0,
    "secondary-base": #0871b8,
    "secondary-dark": #004788,
    "guinot-primary": #ac0040,
    "guinot-secondary": #600024,
    "marycohr-primary": #006938,
    "marycohr-secondary": #2a5135,
    "default-primary": #007bff,
    "default-secondary": #003b7a,
);

$text-colors: (
    "success": map-get($colors, "success"),
    "warning": map-get($colors, "warning"),
    "error": map-get($colors, "error"),
    "white": map-get($colors, "white"),
    "black": map-get($colors, "black"),
    "green": map-get($colors, "green"),
    "blue": map-get($colors, "blue"),
    "disabled": map-get($colors, "disabled"),
    "error-disabled": map-get($colors, "error-disabled"),
);

$box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
