a {
    text-decoration: none;
    color: color("blue_dark");
    cursor: pointer;
}

button {
    border: none;
    background: color("transparent");
    color: color("blue_dark");
    text-align: left;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
