@import "./hx";
@import "./link";

html,
body {
    width: 100%;
    height: 100%;
}

#root {
    height: 100%;
}
