.separator {
    display: block;
    background-color: #ebebeb;
    height: 1px;
    border: none;

    &--medium {
        height: 2px;
    }

    &--large {
        height: 3px;
    }

    @for $i from 0 through 8 {
        &--#{$i} {
            margin-top: $spacing-unit * $i !important;
            margin-bottom: $spacing-unit * $i !important;
        }
    }
}
