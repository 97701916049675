.input {
    &-label {
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 2 * $spacing-unit;
    }

    &-info {
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2 * $spacing-unit;
    }

    &-container {
        min-height: 50px;
        margin-bottom: 2 * $spacing-unit;
        position: relative;

        label,
        .input-adornment__end {
            display: inline-block;
            font-weight: 600;
            padding: 0px 0px;
            margin-bottom: 15px;
        }

        &__icon {
            position: absolute;
            bottom: 13px;
            left: 15px;
            font-size: 20px;

            & ~ input {
                padding-left: 50px !important;
            }
        }

        &_error {
            padding: 5px 5px 0px 5px;
            display: inline-block;
            color: map-get($colors, "error");
        }
    }

    &-checkbox {
        label > input[type="checkbox"] {
            margin-right: $spacing-unit;
        }
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="date"],
textarea,
select {
    &:focus {
        outline: 0;
        box-shadow: 0 1.5px 10px 0 rgba(3, 57, 159, 0.1);
    }

    width: 100%;
    min-height: 50px;
    display: inline-flex;
    padding: 0 20px;
    border: 1px #ebebeb solid;

    &:disabled {
        cursor: not-allowed;
        background-color: rgb(202, 202, 202);
    }

    &::placeholder {
        color: #c5c5c5;
    }
}

.input-group {
    display: flex;
    .input-group-symbol {
        display: table;

        width: 15%;
        min-width: 25px;
        max-width: 50px;

        background: #ffffff;
        border: 1px #ebebeb solid;
        border-left: 0;
        &.disabled {
            background: #cacaca;
        }
        span {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
    }
    input[type="text"] {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

textarea {
    padding: 10px 20px;
}

.select-input {
    position: relative;

    &:hover,
    input:hover,
    label:hover {
        cursor: pointer;
    }
}

.select-list {
    position: absolute;
    top: 100%;
    margin: 1px 0 0 0; // Force margin to override browsers default margin
    left: 0;
    right: 0;
    border: 1px solid #ebebeb;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: color("white");
    padding: 0;
    z-index: 100;
    max-height: 150px;
    overflow-y: auto;

    &--rounded {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.select-item {
    list-style: none;
    font-size: 16px;
    text-align: left;
    padding: 5px 20px;
    border-bottom: 1px solid #ebebeb;
    white-space: pre;

    &:nth-last-child(1) {
        border-bottom: none;
    }

    &:hover {
        background-color: color("blue_hover");
        color: color("white");
        cursor: pointer;
    }
}

.autocomplete {
    .selected-options {
        position: relative;

        input {
            padding-right: 50px;
        }

        i {
            position: absolute;
            right: 20px;
            cursor: pointer;
        }
    }
}

.radio-inputs {
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50px;

    label {
        margin: 0 10px;

        & > input {
            margin-right: 10px;
        }
    }

    input:hover,
    label:hover {
        cursor: pointer;
    }
}

.input-checkbox {
    & label {
        font-weight: normal;
        padding: 0px 16px;
    }
}
.checkbox {
    margin-right: 5px;

    &-group {
        list-style: none;
        padding-left: 0px 32px 0px 0px;
        margin: 0px;
    }

    &-item {
        margin: 2px 0px;
        & label {
            margin: 0px;
            padding: 0px 10px;
            color: black;
        }
    }
}

.SingleDatePicker {
    width: 100%;

    .DateInput_input {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        font-weight: 400;
    }

    .SingleDatePickerInput {
        width: 100%;

        .DateInput {
            width: 100%;
        }
    }

    &_picker {
        z-index: 1013 !important; // Workaround for single datepicker in modal
    }

    & .CalendarDay__selected,
    & .CalendarDay__selected:active,
    & .CalendarDay__selected:hover {
        background: color("blue");
        border-color: color("blue_hover");
    }
}

.DateRangePicker {
    width: 100%;

    .DateRangePickerInput {
        background: none;
    }

    .DateInput {
        min-width: 150px;
    }

    .DateRangePickerInput_arrow {
        padding: 0 10px;
    }

    & .CalendarDay__selected,
    & .CalendarDay__selected:active,
    & .CalendarDay__selected:hover {
        background: color("blue");
        border-color: color("blue_hover");
    }

    & .CalendarDay__selected_span,
    & .CalendarDay__selected_span:hover {
        background: color("blue_hover");
        border-color: color("blue_hover");
    }

    & .CalendarDay__hovered_span,
    & .CalendarDay__hovered_span:hover {
        background: color("blue_hover");
        border-color: color("blue_hover");
        color: color("white");
    }

    & .CalendarMonth_caption {
        font-size: 14px;
        padding-top: 26px;
        padding-bottom: 40px;
    }
}

.input-inline {
    position: relative;
    display: inline-flex;
    align-items: center;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 5px 0px;
    background-color: color("white");
    margin: 5px 5px;
    min-height: 30px;

    label,
    .input-adornment__end {
        display: inline-flex;
        margin-bottom: 0;
        text-transform: uppercase;
        color: #707070;
        font-size: 14px;
        white-space: nowrap;
        padding: 0px 10px;
    }

    &.disabled {
        background-color: #d5d5d5;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    input[type="date"],
    textarea,
    select {
        &:focus {
            outline: 0;
            box-shadow: none;
        }

        width: auto;
        border-radius: 0px;
        min-height: 13px;
        border: none;
        border-left: 1px #ebebeb solid;
        background-color: "transparent";
        font-size: 14px;

        &:disabled {
            background-color: #d5d5d5;
        }
    }

    & > input:first-child {
        padding: 0 5px;
        border-left: none;
    }

    .select-list {
        top: 100%;
        border-radius: 10px;
        margin: 1px 0 0 0;
        background-color: color("white");
        padding: 0;
        z-index: 100;
    }

    .select-item {
        padding: 5px 10px;

        &:nth-last-child(1) {
            border-bottom: none;
        }
    }

    & .DateRangePicker {
        & > div {
            display: inline-flex;
        }
        display: inline-flex;
        border-left: 1px #ebebeb solid;

        &Input {
            display: inline-flex;

            &_arrow {
                display: inline-flex;
                align-items: center;
                vertical-align: initial;
            }
        }

        & .DateInput {
            display: inline-flex;
            align-items: center;
            vertical-align: initial;
            min-width: 120px;

            width: auto;

            &_input {
                border: none;
                width: 120px;
                line-height: inherit;
            }
        }
    }

    &.switch-container {
        border: none;
        background: inherit;
        vertical-align: middle;

        .switch {
            label {
                width: 48px;
                height: 20px;
            }

            .slider::before {
                height: 13px;
                width: 13px;
            }
        }
    }

    & .autocomplete {
        display: flex;
    }
}

/* The switch - the box around the slider */
.switch {
    display: flex !important;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 !important;

    & > label {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-bottom: 0 !important;
        margin-right: 10px;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }

    input:checked + .slider {
        background-color: color("blue");
    }

    input:focus + .slider {
        box-shadow: 0 0 1px color("blue");
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
}
