$calendar-header-height: 60px;
$calendar-day-height: 90px;
$calendar-border-color: color("grey");
$border-radius: 4px;

.calendrier {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-financing {
        margin: 20px 0;
    }

    &-calendar {
        display: flex;
    }

    &-legend {
        margin-right: 5px;
    }

    &-filters {
        &-event {
            display: flex;
            align-items: stretch;

            &:before {
                content: "";
                width: 7px;
                min-width: 7px;
                margin-right: 7px;
            }
        }
    }

    &-grid {
        flex-grow: 1;
        width: 100%;
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &--cell {
            width: 100%;
            font-weight: bold;
            height: $calendar-header-height;
            display: flex;
            justify-content: center;
            align-items: center;
            color: color("shades-black");
            font-size: 16px;

            & + & {
                border-left: 1px solid $calendar-border-color;
            }
        }
    }

    &-month {
        width: 100%;
        background-color: color("grey-light");
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        height: 100%;
    }

    &-week {
        position: relative;
        width: 100%;
        // height: 130px;
        display: flex;
        justify-content: space-between;
        background-color: color("grey-light");

        &--day {
            position: absolute;
            display: flex;
            width: 100%;

            .calendrier-day {
                border-top: 1px solid $calendar-border-color;
            }
        }

        &--content {
            width: 100%;
        }

        &--period-events {
            display: flex;
            flex-direction: column;
            position: relative;
            // overflow: hidden;
            width: 100%;
            margin-top: 35px;
        }

        &--period-event {
            margin: 2px 0;
            padding: 5px 10px;
            background-color: red;
            border-radius: 4px;
            cursor: pointer;
        }

        &--events {
            display: flex;
            width: 100%;
        }

        &-events {
            &--list {
                display: flex;
                position: relative;
                // overflow: hidden;
                width: 100%;
            }
        }
    }

    &-day {
        width: 100%;
        padding: 10px 5px 5px 5px;
        height: $calendar-day-height;
        background-color: color("shades-grey");
        position: relative;

        & + & {
            border-left: 1px solid $calendar-border-color;
        }

        &--full {
            height: unset;
            min-height: $calendar-day-height;

            .calendrier-event {
                height: 25px;

                p {
                    font-size: 1vw;
                }
            }
        }

        &--header {
            width: 100%;
            text-align: center;
        }

        &--number {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.3px;
            display: inline-block;
            text-align: center;
            white-space: nowrap;
            width: max-content;
            min-width: 24px;
            color: color("grey-dark");
            line-height: 16px;
        }

        &--out-of-month {
            background-color: color("grey-300");
        }
    }

    &-event {
        margin-bottom: 10px;
        height: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        p {
            display: -webkit-box;
            max-width: 90%;
            text-overflow: ellipsis;
            font-size: 1vw;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @include media-breakpoint-down("medium") {
                font-size: 1.2vw;
            }
        }

        &:before {
            content: "";
            display: block;
            width: 7px;
            height: 100%;
            margin-right: 7px;
        }

        &--pending:before {
            background-color: color("calendar-blue");
        }
        &--in_progress:before {
            background-color: color("calendar-yellow");
        }
        &--to_validate:before {
            background-color: color("calendar-red");
        }
        &--finished:before {
            background-color: color("calendar-green");
        }
    }

    &-tooltip {
        position: absolute;
        z-index: 20;
        border-radius: $border-radius;
        background-color: color("shades-white");
        border: 1px solid $calendar-border-color;
        padding: $spacing-unit $spacing-unit;
        overflow: auto;

        &--month,
        &--week {
            width: 325%;
        }

        &--day {
            width: 50%;
            left: 25%;
        }

        &--period {
            width: 45%;
        }

        &--left {
            right: 100%;
        }

        &-period--left {
            right: 15%;
        }

        &--right {
            left: 100%;
        }

        &-period--right {
            left: 15%;
        }

        &-period--top,
        &--top {
            top: 0;
        }

        &-period--bottom,
        &--bottom {
            bottom: 0;
        }

        &-header {
            display: flex;
            align-items: center;
            position: relative;
            height: 28px;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background: transparent;
            border: 2px solid color("grey-base");

            &::after,
            &::before {
                content: "";
                display: block;
                height: 80%;
                width: 2px;
                background-color: color("grey-base");
                position: absolute;
                top: 10%;
                left: calc(50% - 1px);
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }

            @include event_attention() {
                border: 2px solid darken(color("grey-base"), 10);
                text-decoration: none;
                outline-width: 0;
                &::after,
                &::before {
                    background-color: darken(color("grey-base"), 10);
                }
            }
        }
    }

    &-table {
        margin-top: $spacing-unit;

        .table__col:first-child {
            text-align: left;
            font-weight: bold;
            color: color("shades-black");
        }
    }

    &_data {
        padding: $spacing-unit * 2;
        background-color: color("shades-white");
        border-radius: $border-radius;
        box-shadow: $box-shadow;

        .chart-tooltip__item-value {
            text-align: right;
        }
    }
}
