.display-values {
    label {
        display: inline-block;
        color: map-get($colors, "black");
        padding-right: 16px;
        margin-bottom: 10px;
        font-weight: bold;

        &:after {
            content: " :";
        }
    }
}
