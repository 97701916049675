::selection {
    background: color("blue");
    background: rgba(color("blue"), 0.3);
}

::-moz-selection {
    background: color("blue");
    background: rgba(color("blue"), 0.3);
}

* {
    -webkit-tap-highlight-color: rgba(color("blue"), 0.2);
}
