.accordion {
    &-title {
        display: flex;
        align-items: center;

        font-size: 16px;
        color: color("blue");

        &-label {
            white-space: nowrap;
            margin-right: 16px;
            font-weight: bold;
        }

        &-right-label {
            margin-left: 16px;
        }
    }

    &-content {
        padding: 32px 0px;
    }
}
